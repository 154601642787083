import {
  createSlice,
  Dispatch,
  PayloadAction,
  SliceCaseReducers,
  ThunkDispatch,
} from "@reduxjs/toolkit";

import { authenticationApi } from "../../../api/authentication";
import { RegisteredLocation } from "../../../location.config";
import { IReduxState } from "../../../redux/store";

export interface IAuthenticationTokenPayload {
  id: string;
  defaultLocationId: RegisteredLocation;
  locations: RegisteredLocation[];
  roles: string[];
  username: string;
  error?: false;
}

export interface IAuthenticationState {
  isCheckingStatus: boolean;
  isDeviceAuthenticated: boolean;
  defaultLocationId: RegisteredLocation;
  locations: RegisteredLocation[];
  roles: string[];
  username?: string;
}

export interface IAuthenticationAwareState {
  authentication: IAuthenticationState;
}

const slice = createSlice<
  IAuthenticationState,
  SliceCaseReducers<IAuthenticationState>
>({
  name: "authentication",
  initialState: {
    defaultLocationId: "ellis-bros",
    isCheckingStatus: true,
    isDeviceAuthenticated: false,
    locations: [],
    roles: [],
    username: undefined,
  },
  reducers: {
    logout(state) {
      state.locations = [];
      state.roles = [];
      state.username = undefined;
    },
    setDeviceAuthenticated(state, action: PayloadAction<boolean>) {
      state.isDeviceAuthenticated = action.payload;
    },
    onUserAuthenticated(
      state,
      action: PayloadAction<IAuthenticationTokenPayload>
    ) {
      const { payload } = action;

      state.defaultLocationId = payload.defaultLocationId;
      state.locations = payload.locations;
      state.roles = payload.roles;
      state.username = payload.username;

      state.isDeviceAuthenticated = true;
      state.isCheckingStatus = false;
    },
    reset(state) {
      state.isDeviceAuthenticated = false;
      state.locations = [];
      state.roles = [];
      state.username = undefined;
    },
    setIsCheckingStatus(state, action) {
      state.isCheckingStatus = action.payload;
    },
  },
});

export const { caseReducers, reducer, name } = slice;

export const {
  logout,
  onUserAuthenticated,
  reset,
  setDeviceAuthenticated,
  setIsCheckingStatus,
} = slice.actions;

export const checkSessionStatus: any = () => async (
  dispatch: ThunkDispatch<IReduxState, unknown, any>
) => {
  const response = await authenticationApi.checkStatus();

  if (response.error === true) {
    window.localStorage.removeItem("token");

    dispatch(setIsCheckingStatus(false));

    if (response.deviceError === false) {
      dispatch(setDeviceAuthenticated(true));
    }
  } else {
    dispatch(onUserAuthenticated(response));
  }
};

export const submitDeviceCode = (code: string) => async (
  dispatch: Dispatch
) => {
  const response = await authenticationApi.submitDeviceCode(code);

  if (!response) {
    return false;
  }

  dispatch(setDeviceAuthenticated(true));

  return true;
};

export const submitLoginCredentials = (
  username: string,
  password: string,
  remember: boolean
) => async (dispatch: Dispatch) => {
  const response = await authenticationApi.login(username, password);

  if (typeof response === "string") {
    return response;
  }

  if (remember) {
    localStorage.setItem("username", username);
  } else {
    localStorage.removeItem("username");
  }

  dispatch(onUserAuthenticated(response));

  // save default location to use as default theme.
  if (remember) {
    const defaultLocation = response.defaultLocationId;
    localStorage.setItem("defaultLocationId", defaultLocation);
  } else {
    localStorage.removeItem("defaultLocationId");
  }

  return true;
};

export const cancelSessionAndLogout = () => async (dispatch: Dispatch) => {
  dispatch(logout(null));
  await authenticationApi.logout();
};
