import { useState } from "react";

import { makeStyles, Typography } from "@material-ui/core";

import EmployeeOnlyNotice from "./employee-only-notice";
import PinEntry from "./pin-entry";
import { useDispatch } from "react-redux";
import { submitDeviceCode } from "../redux/authentication.slice";

const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(2, "auto", 2),
    maxWidth: 340,
  },
  error: {
    color: "red",
  },
  text: {
    marginTop: theme.spacing(1),
  },
}));

function AuthenticateDevice() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [acceptedEmployeeOnly, setAcceptedEmployeeOnly] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  if (!acceptedEmployeeOnly) {
    const handleContinue = () => setAcceptedEmployeeOnly(true);

    return <EmployeeOnlyNotice onContinue={handleContinue} />;
  }

  const handleCodeSubmit = async (code: string) => {
    setError(null);
    setIsLoading(true);

    const result = await dispatch(submitDeviceCode(code));

    if (!result) {
      setError("Invalid/expired code");
      setIsLoading(false);
      return false;
    }

    return true;
  };

  return (
    <>
      <Typography align="center" variant="h6">
        Enter Access Code
      </Typography>
      <Typography
        align="center"
        className={classes.text}
        component="p"
        variant="caption"
      >
        We need to identify your device before you can login.
      </Typography>
      <PinEntry
        className={classes.input}
        disabled={isLoading}
        onSubmit={handleCodeSubmit}
      />
      {error && (
        <Typography
          align="center"
          className={classes.error}
          component="p"
          variant="caption"
        >
          <strong>Invalid:</strong> {error}
        </Typography>
      )}
    </>
  );
}

export default AuthenticateDevice;
