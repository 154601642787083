import { RegisteredLocation } from "../../../location.config";
import { IReduxState } from "../../../redux/store";

export const selectDefaultUsername = () => {
  return localStorage.getItem("username") ?? "";
};

export const selectIsCheckingAuthenticationStatus = (state: IReduxState) => {
  return state.authentication.isCheckingStatus;
};

export const selectIsDeviceAuthenticated = (state: IReduxState) => {
  return state.authentication.isDeviceAuthenticated;
};

export const selectIsUserAuthenticated = (state: IReduxState) => {
  return Boolean(state.authentication.username);
};

export const selectIsAuthenticated = (state: IReduxState) => {
  return selectIsDeviceAuthenticated(state) && selectIsUserAuthenticated(state);
};

export const selectActiveUsername = (state: IReduxState) => {
  return state.authentication.username;
};

export const selectUserDefaultLocation = (
  state: IReduxState
): RegisteredLocation => {
  return "ellis-bros"; // state.authentication.defaultLocationId;
};

export const selectUserLocations = (
  state: IReduxState
): RegisteredLocation[] => {
  return ["ellis-bros"]; // state.authentication.locations;
};
