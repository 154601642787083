import axios, { AxiosError } from "axios";

const isDev = process.env.NODE_ENV !== "production";

const baseURL = isDev ? "/v1" : "https://api.ellisbros.co.uk/v1";

/**
 * Init a shared axios instance
 */
const instance = axios.create({ baseURL, withCredentials: true });

/**
 * Called by the auth reducers to update the instances token.
 */
export function updateAxiosInstanceToken(token: string) {
  instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

const storedToken = window.localStorage.getItem("token");

// set the token to the one found in the localStorage to be
// validated on app mount.
storedToken && updateAxiosInstanceToken(storedToken);

// Add a response interceptor to refresh the page if the api responds
instance.interceptors.response.use(
  (response) => response, // 2XX RANGE - Success
  (err: AxiosError) => {
    if (
      err.response?.status === 401 &&
      err.response.data.message !== "Forbidden resource" &&
      !err.request.responseURL.includes("/v1/auth/")
    ) {
      window.location.hash = "sessionExpired";
      window.location.reload();
    }

    // re-throw the error to be handled as usual
    return Promise.reject(err);
  }
);

export const transformResponseError = (err: any, join = "<br/>") => {
  let message = err.response.data?.message;

  if (message && Array.isArray(message)) {
    message = message.join(join);
  }

  return { error: message ?? err.response.data?.error ?? "Bad request" };
};

export default instance;
