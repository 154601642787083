import { BrowserRouter, Route } from "react-router-dom";

/**
 * Just makes the `params.locationId` available if set.
 *
 * The `CurrentLocationProvider` is in charge of keeping the user
 * within its allowed locations.
 */
function AppRouter({ children }: any) {
  return (
    <BrowserRouter>
      <Route exact path="/">
        {children}
      </Route>
      <Route path="/:locationId">{children}</Route>
    </BrowserRouter>
  );
}

export default AppRouter;
