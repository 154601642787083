import { IModule } from "redux-dynamic-modules-core";

import {
  checkSessionStatus,
  IAuthenticationAwareState,
  reducer,
} from "./authentication.slice";

export function getAuthenticationModule(): IModule<IAuthenticationAwareState> {
  return {
    id: "authentication",
    reducerMap: {
      authentication: reducer,
    },
    initialActions: [checkSessionStatus()],
    finalActions: [],
  };
}
