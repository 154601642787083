import Header from "./header";

import { CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    backgroundColor: "#eeeeee",
    display: "flex",
    justifyContent: "center",
    height: "100vh",
  },
  content: {
    padding: theme.spacing(2),
  },
  holder: {
    backgroundColor: "white",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    width: 410,
  },
  loader: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    minHeight: 100,
  },
}));

type HolderProps = {
  children?: React.ReactNode;
  isLoading?: boolean;
  title?: string;
};

function AppHolder({
  children,
  isLoading,
  title = "Authentication",
}: HolderProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <main className={classes.holder}>
        {title ? <Header title={title} /> : null}
        <div className={classes.content}>
          {isLoading ? (
            <div className={classes.loader}>
              <CircularProgress size={40} />
            </div>
          ) : (
            children
          )}
        </div>
      </main>
    </div>
  );
}

export default AppHolder;
