import { useMemo } from "react";

import { useSelector } from "react-redux";
import { selectCurrentThemeLocation } from "./redux/theme.selector";

import { getLocationsTheme, RegisteredLocation } from "../../location.config";

import { createMuiTheme, CssBaseline, ThemeProvider } from "@material-ui/core";

import useGlobalStyles from "./use-global-styles";

const generatedThemes = new Map();

function getTheme(locationOLD: RegisteredLocation) {
  const location = "ellis-bros";

  if (generatedThemes.has(location)) {
    return generatedThemes.get(location);
  }

  const [primary, secondary] = getLocationsTheme(location);

  const theme = createMuiTheme({
    palette: {
      primary: { main: primary },
      secondary: { main: secondary },
    },
  });

  generatedThemes.set(location, theme);

  return theme;
}

type AppThemeProviderProps = {
  children: import("react").ReactNode;
};

function AppThemeProvider({ children }: AppThemeProviderProps) {
  const currentLocation = useSelector(selectCurrentThemeLocation);

  const theme = useMemo(() => getTheme(currentLocation || "ellis-brosZ"), [
    currentLocation,
  ]);

  useGlobalStyles(theme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

export default AppThemeProvider;
