import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from "@reduxjs/toolkit";

import { RegisteredLocation } from "../../../location.config";

export interface IThemeState {
  current: RegisteredLocation;
}

export interface IThemeAwareState {
  theme: IThemeState;
}

const defaultLocation =
  localStorage.getItem("defaultLocationId") ?? "ellis-bros";

const slice = createSlice<IThemeState, SliceCaseReducers<IThemeState>>({
  name: "authentication",
  initialState: {
    current: defaultLocation as RegisteredLocation,
  },
  reducers: {
    setCurrentTheme(state, action: PayloadAction<RegisteredLocation>) {
      state.current = action.payload;
    },
  },
});

export const { caseReducers, reducer, name } = slice;

export const { setCurrentTheme } = slice.actions;
