import { useSelector } from "react-redux";
import {
  selectIsCheckingAuthenticationStatus,
  selectIsDeviceAuthenticated,
} from "./redux/authentication.selector";

import Holder from "../holder";
import AuthenticateDevice from "./device";
import AuthenticateUser from "./user/user";
import { Helmet } from "react-helmet";

type AuthenticationProps = {
  loading?: boolean;
};

function Authentication({ loading = false }: AuthenticationProps) {
  const isCheckingStatus = useSelector(selectIsCheckingAuthenticationStatus);
  const isDeviceAuthenticated = useSelector(selectIsDeviceAuthenticated);

  return (
    <Holder isLoading={isCheckingStatus || loading}>
      <Helmet>
        <title>Authentication | Ellis Bros Panel</title>
      </Helmet>
      {isDeviceAuthenticated ? <AuthenticateUser /> : <AuthenticateDevice />}
    </Holder>
  );
}

export default Authentication;
