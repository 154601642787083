import { Suspense } from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import store from "./redux/store";

import "./utils/display-session-expired";
// import "./components/inputs/yup-setup";

import ThemeProvider from "./app/theme/theme-provider";

import App from "./app";
import AppHolder from "./app/holder";

import RouterProvider from "./app/providers/router-provider";

import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <RouterProvider>
      <ThemeProvider>
        <Suspense fallback={<AppHolder isLoading={true} />}>
          <App />
        </Suspense>
      </ThemeProvider>
    </RouterProvider>
  </Provider>,
  // </React.StrictMode>
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
