import { IModule } from "redux-dynamic-modules-core";

import { reducer, IThemeAwareState } from "./theme.slice";

export function getThemeModule(): IModule<IThemeAwareState> {
  return {
    id: "theme",
    reducerMap: { theme: reducer },
  };
}
