import { lazy } from "react";

import { Route, Switch } from "react-router";

const PasswordResetRoute = lazy(() => import("./password-reset"));

function ExternalRoutes() {
  return (
    <Switch>
      <Route path="/ext/password-reset/:token">
        <PasswordResetRoute />
      </Route>
    </Switch>
  );
}

export default ExternalRoutes;
