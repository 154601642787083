import { createRef, useState } from "react";

import clsx from "clsx";

import { makeStyles, TextField } from "@material-ui/core";

// creates an empty array of `filler`s
const empty = (length, filler = null) => Array(length).fill(filler);

const defaultCharacterValidator = (value) => /^[A-Z0-9]$/i.test(value);

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  input: {
    width: 45,
    "& input": {
      textAlign: "center",
    },
  },
}));

/**
 * Length can not be changed after initial mount.
 *
 * Calls onSubmit with completed string, resolve with `false` to reset state.
 */
const DeviceCodePinEntry = ({
  characterValidator = defaultCharacterValidator,
  className,
  disabled = false,
  length = 6,
  onSubmit = async (code) => {
    return false;
  },
}) => {
  const [inputRefs] = useState(() => empty(length).map(() => createRef()));
  const [values, setValues] = useState(() => empty(length, ""));

  const classes = useStyles();

  // helper function to highlight an input
  const focusInput = (index) => {
    setTimeout(() => inputRefs[index].current.focus());
  };

  // helper function to reset state
  const reset = () => {
    focusInput(0);
    setValues(empty(length, ""));
  };

  // update state and move forward
  const handleChange = (event) => {
    const { name, value } = event.target;

    const focusedIndex = +name;

    // ensure valid character
    if (value && !characterValidator(value)) {
      return;
    }

    setValues((currentValues) => {
      const newValues = [...currentValues];
      newValues[focusedIndex] = value.toUpperCase();
      return newValues;
    });

    // move forward if not last input
    if (focusedIndex + 1 < length) {
      focusInput(focusedIndex + 1);
    }
  };

  // ensures the first empty field is selected
  const handleFocus = (event) => {
    const { name } = event.target;

    let focusedIndex = +name;

    const firstEmpty = values.indexOf("");

    // ensure we're at the first empty input
    if (firstEmpty !== -1 && focusedIndex > firstEmpty) {
      focusedIndex = firstEmpty;
    }

    // clear selected input and any after
    setValues((currentValues) => {
      const newValues = [...currentValues];

      for (let i = focusedIndex; i < length; i++) {
        newValues[i] = ""; // clear selected field and any afterwards
      }

      return newValues;
    });

    // if the focus needs to be changed
    if (focusedIndex !== +name) {
      focusInput(focusedIndex);
    }
  };

  // listen for `backspace` key and move back
  const handleKeyDown = (event) => {
    const focusedIndex = +event.target.name;

    // backspace character - move one input back
    if (event.keyCode === 8 && focusedIndex > 0) {
      focusInput(focusedIndex - 1);
    }
  };

  // submits when all inputs filled
  const handleKeyUp = (event) => {
    if (values.filter(Boolean).length !== length) {
      return;
    }

    // call onSubmit with the value and if it returns false reset.
    onSubmit(values.join("")).then((response) => {
      if (response === false) {
        reset();
      }
    });
  };

  const getInputProps = (index) => ({
    autoFocus: index === 0,
    className: classes.input,
    disabled,
    name: index.toString(),
    onChange: handleChange,
    onFocus: handleFocus,
    onKeyDown: handleKeyDown,
    onKeyUp: handleKeyUp,
    inputRef: inputRefs[index],
    size: "small",
    value: values[index],
    variant: "outlined",
  });

  return (
    <div className={clsx(classes.root, className)}>
      {values.map((value, index) => (
        <TextField key={index} {...getInputProps(index)} />
      ))}
    </div>
  );
};

export default DeviceCodePinEntry;
