import { AxiosError } from "axios";

import axios, {
  transformResponseError,
  updateAxiosInstanceToken,
} from "./_instance";

import { IAuthenticationTokenPayload } from "../app/authentication/redux/authentication.slice";

import { getTokenPayload } from "../utils/get-token-payload";

async function checkStatus() {
  return axios
    .get<IAuthenticationTokenPayload & { error: false }>("/auth/status")
    .then((response) => response.data)
    .catch((err: AxiosError) => {
      const message: string = err.response?.data?.message;
      const status = err.response?.status ?? 400;

      if (status === 401 && message) {
        // if the device is invalid the message would read 'Unauthorised device'
        if (message.includes("device")) {
          return {
            error: true as true,
            deviceError: true,
            userError: false,
          };
        }
        // otherwise if it's the user it will read 'Unauthorised user'
        else {
          return {
            error: true as true,
            deviceError: false,
            userError: true,
          };
        }
      }

      return {
        error: true as true,
        deviceError: false,
        userError: false,
      };
    });
}

async function submitDeviceCode(code: string) {
  const normalised = code.toUpperCase();

  return axios
    .post(`/auth/device/challenge/${normalised}`)
    .then(() => true)
    .catch(() => false);
}

async function login(
  username: string,
  password: string
): Promise<IAuthenticationTokenPayload | string> {
  let token: string;

  try {
    const response = await axios.post("/auth/login", { username, password });
    token = response.data.token;
  } catch (e) {
    switch (e.response.status) {
      case 500: {
        return "An error occurred whilst connecting to the service.";
      }
      case 401: {
        return "No account found with this username/password.";
      }
      default: {
        return `Unable to complete request (${e.response.status})`;
      }
    }
  }

  const { jti, sub, ...payload } = getTokenPayload(token);

  payload.id = sub;

  // is automatically attached to future requests
  updateAxiosInstanceToken(token);
  // save the token to local storage
  localStorage.setItem("token", token);

  return payload;
}

async function logout() {
  try {
    await axios.delete("/auth/login");
    return true;
  } catch {
    return false;
  }
}

export type PasswordResetTokenPayload = {
  type: "PASSWORD_RESET";
  jti: string;
  sub: string;
  displayName: string;
  username: string;
  defaultLocationId: string;
  deviceId?: number;
  deviceName?: string;
  issuedBy: string;
  error: null;
};

export async function sendPasswordResetToken(
  userId: string,
  deviceId?: number
) {
  const result = await axios.post<{ success: boolean }>(
    `/auth/password-reset/${userId}`,
    null,
    { params: { deviceId } }
  );

  return Boolean(result.data?.success);
}

export async function validatePasswordResetToken(
  token: string
): Promise<PasswordResetTokenPayload | { error: string }> {
  try {
    const result = await axios.post<PasswordResetTokenPayload>(
      "/auth/password-reset/status",
      { token }
    );

    return { error: null, ...result.data };
  } catch (err: any) {
    const message: string = err.response?.data?.message || "Network error";

    return { error: message };
  }
}

export async function submitPasswordResetToken(
  token: string,
  password: string
) {
  try {
    const result = await axios.post<PasswordResetTokenPayload>(
      `/auth/password-reset`,
      { token, password }
    );

    return { error: null, ...result.data };
  } catch (err: any) {
    return transformResponseError(err);
  }
}

// export in an object to keep global namespace tidy(ish)
export const authenticationApi = {
  checkStatus,
  login,
  logout,
  submitDeviceCode,
};
