import { useRef, useState } from "react";

import { useDispatch } from "react-redux";
// import { useHistory } from "react-router-dom";

// import { onAuthSuccess } from "app/auth/slice";

// import validateEmail from "util/validate-email";

// import useCurrentProperty from "hooks/use-current-property";

import {
  Button,
  Checkbox,
  FormControlLabel,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { selectDefaultUsername } from "../redux/authentication.selector";
import { useSelector } from "react-redux";
import { submitLoginCredentials } from "../redux/authentication.slice";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  username: {
    marginTop: theme.spacing(2),
  },
  password: {
    marginTop: theme.spacing(2),
  },
  remember: {
    margin: theme.spacing(2, 0, 0),
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(2),
  },
  errorDisplay: {
    color: "red",
    marginTop: theme.spacing(2.5),
  },
}));

const AuthenticateUser = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const passwordEl = useRef<HTMLElement>();

  // autofill the username field
  const storedUsername = useSelector(selectDefaultUsername);

  const hadStoredUsername = Boolean(storedUsername);

  const [state, setState] = useState({
    error: false as false | string,
    username: storedUsername ?? "",
    password: "",
    remember: hadStoredUsername,
  });

  const [isLoading, setIsLoading] = useState(false);

  const isValid = state.password.length >= 2 && state.password.length > 3;

  const handleChange = (event: any) => {
    const { checked, name, type, value } = event.target;

    setState((currentState) => ({
      ...currentState,
      error: false,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleReset = (error: string) => {
    setIsLoading(false);

    setState((currentState) => ({
      ...currentState,
      password: "",
      error,
    }));

    passwordEl.current?.focus();
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!isValid) return;

    setIsLoading(true);

    const { username, password, remember } = state;

    const response = await dispatch(
      submitLoginCredentials(username, password, remember)
    );

    if (typeof response === "string") {
      handleReset(response ?? "An error occurred");
    }
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <TextField
        autoFocus={!hadStoredUsername}
        className={classes.username}
        disabled={isLoading}
        fullWidth
        label="Username"
        name="username"
        onChange={handleChange}
        value={state.username}
        variant="outlined"
      />
      <TextField
        autoFocus={hadStoredUsername}
        className={classes.password}
        disabled={isLoading}
        fullWidth
        inputRef={passwordEl}
        label="Password"
        name="password"
        onChange={handleChange}
        type="password"
        value={state.password}
        variant="outlined"
      />
      {state.error && (
        <Typography
          align="center"
          className={classes.errorDisplay}
          component="p"
          variant="body2"
        >
          {state.error}
        </Typography>
      )}
      <FormControlLabel
        className={classes.remember}
        disabled={isLoading}
        label="Remember me"
        control={
          <Checkbox
            color="default"
            checked={state.remember}
            name="remember"
            onChange={handleChange}
            size="small"
          />
        }
      />
      <Button
        className={classes.button}
        disabled={!isValid || isLoading}
        color="primary"
        fullWidth
        type="submit"
        variant="contained"
      >
        Login
      </Button>
    </form>
  );
};

export default AuthenticateUser;
