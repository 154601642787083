import { Action } from "redux";
import { createStore } from "redux-dynamic-modules-core";
import { getThunkExtension } from "redux-dynamic-modules-thunk";
import { ThunkAction } from "redux-thunk";

import { IAuthenticationState } from "../app/authentication/redux/authentication.slice";
import { IPanelState } from "../app/panel/redux/panel.slice";
import { getThemeModule } from "../app/theme/redux/theme.module";
import { IThemeState } from "../app/theme/redux/theme.slice";
import { IContractorUtilitiesState } from "../features/contractors/redux/contractor-utilities.slice";
import { IDeviceState } from "../features/devices/redux/devices.slice";
import { IUserState } from "../features/users/redux/users.slice";

export interface IReduxState {
  authentication: IAuthenticationState;
  contractorUtilities: IContractorUtilitiesState;
  device: IDeviceState;
  panel: IPanelState;
  theme: IThemeState;
  user: IUserState;
}

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  IReduxState,
  unknown,
  Action<string>
>;

const store = createStore<IReduxState>(
  {
    initialState: {},
    enhancers: [],
    extensions: [getThunkExtension()],
  },
  getThemeModule()
  // ... other initial modules
);

export default store;
