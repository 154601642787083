import { lazy } from "react";

import { useLocation } from "react-router";

import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "./authentication/redux/authentication.selector";

import Authentication from "./authentication";

import ExternalRoutes from "../external";
import AuthenticationStateProvider from "./providers/authentication-state-provider";
import AppHolder from "./holder";

const Panel = lazy(() => import("./panel"));

type PanelComponentProps = {
  loadingComponent: React.ReactNode;
};

function PanelRoutes({ loadingComponent }: PanelComponentProps) {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  return isAuthenticated ? (
    <Panel renderWhilstInitialising={loadingComponent} />
  ) : (
    <Authentication />
  );
}

function App() {
  const { pathname } = useLocation();

  const isExternal = pathname.startsWith("/ext");

  return isExternal ? (
    <ExternalRoutes />
  ) : (
    <AuthenticationStateProvider>
      <PanelRoutes loadingComponent={<AppHolder isLoading={true} />} />
    </AuthenticationStateProvider>
  );
}

export default App;
