import { getAuthenticationModule } from "../authentication/redux/authentication.module";
import { DynamicModuleLoader } from "redux-dynamic-modules-react";

type AuthenticationStateProviderProps = {
  children: React.ReactNode;
};

function AuthenticationStateProvider({
  children,
}: AuthenticationStateProviderProps) {
  return (
    <DynamicModuleLoader modules={[getAuthenticationModule()]}>
      {children}
    </DynamicModuleLoader>
  );
}

export default AuthenticationStateProvider;
