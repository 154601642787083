import { Button, makeStyles, Typography } from "@material-ui/core";

import { ErrorOutlineOutlined as WarningIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  icon: {
    color: "red",
    fontSize: "6em",
  },
  text: {
    marginTop: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

interface AuthenticationEmployeeOnlyNoticeProps {
  onContinue: () => void;
}

function AuthenticationEmployeeOnlyNotice({
  onContinue,
}: AuthenticationEmployeeOnlyNoticeProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <WarningIcon className={classes.icon} />
      <Typography className={classes.text} variant="h6">
        Unregistered Device
      </Typography>
      <Typography className={classes.text} component="p" variant="caption">
        This panel is for authorised Ellis Bros employees only.
      </Typography>
      <Button
        className={classes.button}
        color="primary"
        onClick={onContinue}
        size="small"
        variant="contained"
      >
        Continue
      </Button>
    </div>
  );
}

export default AuthenticationEmployeeOnlyNotice;
