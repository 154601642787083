import swal from "sweetalert2";

/**
 * If a 401 user or device error is caught by the axios interceptor
 * a 'sessionExpired' hash is appended to the url and the page
 * refreshed. Look for this hash on app load, display a notice and
 * remove it.
 */

if (window.location.hash.includes("sessionExpired")) {
  window.location.hash = "";

  setTimeout(() => {
    swal.fire({
      title: "Session Expired",
      html: "Your session expired,<br/> please re-authenticate to continue.",
      icon: "warning",
    });
  }, 250);
}
