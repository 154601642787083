import { makeStyles, Theme } from "@material-ui/core";

const useGlobalStyles = makeStyles({
  "@global": {
    body: {
      touchAction: "pan-x pan-y",
    },
    ".swal2-container": {
      zIndex: "2000 !important",
    },
    ".swal2-styled.swal2-confirm": (theme: Theme) => ({
      backgroundColor: `${theme.palette.primary.main}`,
      borderColor: `${theme.palette.primary.main}`,
    }),
    ".swal2-styled.swal2-deny": {
      backgroundColor: `#30303c`,
      borderColor: "red",
    },
  },
});

export default useGlobalStyles;
