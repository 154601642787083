export const locationConfiguration = {
  airfield: {
    colours: ["#77b344", "#FFFFFF"],
    name: "Skegness Airfield",
  },
  "ellis-bros": {
    colours: ["#E52320", "#0851C7"],
    name: "Ellis Bros (Contractors) LTD",
  },
  greenacres: {
    colours: ["#037841", "#E8FFEE"],
    name: "Greenacres Camping & Touring",
  },
  "nursery-bungalow": {
    colours: ["#295d32", "#86ea98"],
    name: "Nursery Bungalow",
  },
  pro: {
    colours: ["#263238", "#FFFFFF"],
    name: "Property Rental Office",
  },
  swlp: {
    colours: ["#006f5e", "#f7fffc"],
    name: "Skegness Water Leisure Park",
  },
};

export type RegisteredLocation = keyof typeof locationConfiguration;

export const locationKeys = Object.keys(
  locationConfiguration
).sort() as RegisteredLocation[];

export function getLocationsName(locationOLD: RegisteredLocation) {
  const location = "ellis-bros";
  return locationConfiguration[location].name;
}

export function getLocationsTheme(location: RegisteredLocation) {
  return locationConfiguration[location].colours;
}

export default locationConfiguration;
