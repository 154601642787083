import { useRef } from "react";

import { useSelector } from "react-redux";
import { selectCurrentThemeLocation } from "../theme/redux/theme.selector";

import { getLocationsName } from "../../location.config";

import { makeStyles, Theme, Typography, useTheme } from "@material-ui/core";

type UseStylesProps = {
  backgroundColor: string;
};

const useStyles = makeStyles<Theme, UseStylesProps>((theme) => ({
  header: {
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    color: "white",
    padding: theme.spacing(1, 0),
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: 400,
  },
  caption: {
    display: "block",
  },
}));

type AppHolderHeaderProps = {
  title?: string;
};

function AppHolderHeader({ title = "Authentication" }: AppHolderHeaderProps) {
  const theme = useTheme();

  const currentLocation = useSelector(selectCurrentThemeLocation);

  // the location will change during loading, stop theme flickering before navigated away
  const locationName = useRef(getLocationsName(currentLocation));
  const backgroundColor = useRef(theme.palette.primary.main);

  const classes = useStyles({ backgroundColor: backgroundColor.current });

  return (
    <header className={classes.header}>
      <Typography align="center" className={classes.caption} variant="caption">
        {locationName.current}
      </Typography>
      <Typography align="center" className={classes.title} variant="h1">
        {title}
      </Typography>
    </header>
  );
}

export default AppHolderHeader;
